import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CONFIG from '../../config';
import PageTitle from '../../components/PageTitle';
import WhiteSquare from '../../components/WhiteSquare';

import img20241 from '../../assets/companyHistory/img_h01.png';
import img20231 from '../../assets/companyHistory/img_h02.jpg';
import img20232 from '../../assets/companyHistory/img_h03.jpg';
import img20233 from '../../assets/companyHistory/img_h04.png';
import img20221 from '../../assets/companyHistory/img_h06.jpg';
import img20222 from '../../assets/companyHistory/img_h07.jpg';
import img20211 from '../../assets/companyHistory/img_h09.png';
import img20212 from '../../assets/companyHistory/img_h10.jpg';
import img20213 from '../../assets/companyHistory/img_h08.jpg';
import img20201 from '../../assets/companyHistory/img_h11.png';
import img20202 from '../../assets/companyHistory/img_h12.png';
import img20203 from '../../assets/companyHistory/img_h13.png';
import img20204 from '../../assets/companyHistory/img_h14.jpg';
import banner1Img from '../../assets/companyHistory/banner1.png';
import banner2Img from '../../assets/companyHistory/banner2.png';

interface I_metaInfo {
  [key: string]: {
    subTitle: string;
    description: JSX.Element | JSX.Element[];
  };
}

const metaInfo: I_metaInfo = {
  story: {
    subTitle: '경영철학',
    description: <p>함께 만들어가는 유쾌한 라이프<br />종합엔터테인먼트 미디어그룹<br className="show-mobile" /> 이엘그룹입니다</p>
  },
  history: {
    subTitle: '회사연혁',
    description: <p>끊임없는 도전을 통해 글로벌 종합엔터테인먼트 기업으로<br/>성장해 나가겠습니다.</p>
  },
  culture: {
    subTitle: '이엘문화',
    description: <p>업계 리더로서의 자부심을 가지고 <br />즐겁게 일하는 이엘 프로페셔널</p>
  },
};

/**
 * 엔터테인먼트 영역 엘리먼트 컴포넌트
 * @constructor
 */
const Entertainment = ():JSX.Element => {
  return (
    <div className="type">
      <i />
      <p>Entertainment</p>
    </div>
  );
}

interface SinceBlockType {
  img: any,
  title: string,
}

interface SinceBlock1ListType {
  dogIsGood: SinceBlockType;
  scoutsEarlyJobter: SinceBlockType;
  myChild: SinceBlockType;
  hahaBus: SinceBlockType;
  capitalistSchool: SinceBlockType;
  returnSingles2: SinceBlockType;
  returnSingles: SinceBlockType;
  stealFate: SinceBlockType;
}

interface SinceBlock2ListType {
  whenTheDevilCallsYourName: SinceBlockType;
  convenienceStoreRestaurant: SinceBlockType;
  getItBeauty: SinceBlockType;
  perfectLife: SinceBlockType;
  porkBellyRhapsody: SinceBlockType;
}

/**
 * 모바일용 포인트 이미지 표시 엘리먼트 컴포넌트
 * @param param
 * @constructor
 */
const MobilePointImg = ({param}:{param:SinceBlockType}):JSX.Element => {
  return <img src={param.img} alt={param.title} />;
}

/** 2021-2024 내 이미지 목록 */
const sinceBlock1List: SinceBlock1ListType = {
  dogIsGood: {img: img20241, title: '개는 훌륭하다'},
  scoutsEarlyJobter: {img: img20231, title: '스카우트4.0 얼리어잡터'},
  myChild: {img: img20232, title: '금쪽같은 내새끼'},
  hahaBus: {img: img20233, title: '하하버스'},
  capitalistSchool: {img: img20221, title: '자본주의학교'},
  returnSingles2: {img: img20222, title: '돌싱글즈'},
  returnSingles: {img: img20211, title: '돌싱글즈'},
  stealFate: {img: img20212, title: '보쌈: 운명을 훔치다'},

};

/** 2015-2020 내 이미지 목록 */
const sinceBlock2List: SinceBlock2ListType = {
  whenTheDevilCallsYourName: {img: img20201, title: '악마가 너의 이름을 부를 때'},
  convenienceStoreRestaurant: {img: img20202, title: '편스토랑과 슈퍼맨이 돌아왔다'},
  getItBeauty: {img: img20203, title: 'Get it Beauty'},
  perfectLife: {img: img20204, title: '퍼펙트 라이프'},
  porkBellyRhapsody: {img: img20213, title: '삼겹살 랩소디'}
}

const CompanyHistory = () => {
  const location = useLocation();
  const { pathname } = location;
  const [selectedTab, setSelectedTab] = useState(2);
  const [scrollY, setScrollY] = useState(0);
  const [scrollActive, setScrollActive] = useState(false);

  // 첫 번째 블럭
  const [sinceBlock1Year, setSinceBlock1Year] = useState(2024);
  const [sinceBlock1Img, setSinceBlock1Img] = useState<SinceBlockType>(sinceBlock1List.dogIsGood);
  const [sinceBlock1Fading, setSinceBlock1Fading] = useState(false);
  // 두 번째 블럭
  const [sinceBlock2Year, setSinceBlock2Year] = useState(2020);
  const [sinceBlock2Img, setSinceBlock2Img] = useState<SinceBlockType>(sinceBlock2List.whenTheDevilCallsYourName);
  const [sinceBlock2Fading, setSinceBlock2Fading] = useState(false);
  // 연도 셀렉터
  const [yearSelector, setYearSelector] = useState(2024);
  const [yearSelectorHide, setYearSelectorHide] = useState(true);

  const scrollFixed = () => {
    // 좌측 영역 고정 처리
    if (scrollY > 100) {
      setScrollY(window.scrollY);
      setScrollActive(true);
    } else {
      setScrollY(window.scrollY);
      setScrollActive(false);
    }

    // 우측 연도 셀렉터
    if(scrollY <= 650) {
      setYearSelectorHide(true);
    }

    // 첫 번째 sinceBlock
    if(scrollY >= 650 && scrollY <= 4300) {
      setYearSelectorHide(false);
      // 좌측 년도 교체
      if(scrollY >= 1700 && scrollY <= 2400) {
        setYearSelector(2023);
        setSinceBlock1Year(2023);
      } else if(scrollY >= 2400 && scrollY <= 3450) {
        setYearSelector(2022);
        setSinceBlock1Year(2022);
      } else if(scrollY >= 3450 && scrollY <= 4300){
        setYearSelector(2021);
        setSinceBlock1Year(2021);
      } else {
        setYearSelector(2024);
        setSinceBlock1Year(2024);
      }
      // 좌측 이미지 교체
      if(scrollY >= 651 && scrollY <= 1800) {
        if (sinceBlock1Img.img !== sinceBlock1List.dogIsGood.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.dogIsGood);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 1800 && scrollY <= 2100) {
        if (sinceBlock1Img.img !== sinceBlock1List.scoutsEarlyJobter.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.scoutsEarlyJobter);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 2100 && scrollY <= 2300) {
        if (sinceBlock1Img.img !== sinceBlock1List.myChild.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.myChild);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 2300 && scrollY <= 2680) {
        if (sinceBlock1Img.img !== sinceBlock1List.hahaBus.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.hahaBus);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 2680 && scrollY <= 3050) {
        if (sinceBlock1Img.img !== sinceBlock1List.capitalistSchool.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.capitalistSchool);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 3050 && scrollY <= 3400) {
        if (sinceBlock1Img.img !== sinceBlock1List.returnSingles2.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.returnSingles2);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 3400 && scrollY <= 3600) {
        if (sinceBlock1Img.img !== sinceBlock1List.returnSingles.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.returnSingles);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 3600 && scrollY <= 3700){
        if (sinceBlock1Img.img !== sinceBlock1List.stealFate.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.stealFate);
            setSinceBlock1Fading(false);
          }, 500);
        }
      }
    }

    // 두 번째 sinceBlock
    if(scrollY >= 4228 && scrollY <= 8000) {
      setYearSelectorHide(false);
      // 좌측 년도 교체
      if(scrollY <= 5838){
        setYearSelector(2020);
        setSinceBlock2Year(2020);
      } else if (scrollY >= 5839 && scrollY <= 6220) {
        setYearSelector(2019);
        setSinceBlock2Year(2019);
        setSinceBlock2Fading(true);
      } else if (scrollY >= 6220 && scrollY <= 6900) {
        setYearSelector(2018);
        setSinceBlock2Year(2018);
        setSinceBlock2Fading(true);
      } else if (scrollY >= 6900) {
        setYearSelector(2017);
        setSinceBlock2Year(2017);
        setSinceBlock2Fading(true);
      }
      // 좌측 이미지 교체
      if (scrollY >= 4228 && scrollY <= 4820) {
        if (sinceBlock2Img.img !== sinceBlock2List.whenTheDevilCallsYourName.img) {
          setSinceBlock2Fading(true);
          setTimeout(() => {
            setSinceBlock2Img(sinceBlock2List.whenTheDevilCallsYourName);
            setSinceBlock2Fading(false);
          }, 500);
        }
      } else if (scrollY >= 4820 && scrollY <= 5200) {
        if (sinceBlock2Img.img !== sinceBlock2List.convenienceStoreRestaurant.img) {
          setSinceBlock2Fading(true);
          setTimeout(() => {
            setSinceBlock2Img(sinceBlock2List.convenienceStoreRestaurant);
            setSinceBlock2Fading(false);
          }, 500);
        }
      } else if(scrollY >= 5200 && scrollY <= 5400) {
        if (sinceBlock2Img.img !== sinceBlock2List.getItBeauty.img) {
          setSinceBlock2Fading(true);
          setTimeout(() => {
            setSinceBlock2Img(sinceBlock2List.getItBeauty);
            setSinceBlock2Fading(false);
          }, 500);
        }
      } else if(scrollY >= 5400 && scrollY <= 5600){
        if (sinceBlock2Img.img !== sinceBlock2List.perfectLife.img) {
          setSinceBlock2Fading(true);
          setTimeout(() => {
            setSinceBlock2Img(sinceBlock2List.perfectLife);
            setSinceBlock2Fading(false);
          }, 500);
        }
      } else if(scrollY >= 5600 && scrollY <= 6200){
        if (sinceBlock2Img.img !== sinceBlock2List.porkBellyRhapsody.img) {
          setSinceBlock2Fading(true);
          setTimeout(() => {
            setSinceBlock2Img(sinceBlock2List.porkBellyRhapsody);
            setSinceBlock2Fading(false);
          }, 500);
        }
      }
    }
  };

  useEffect(() => {
    const scrollListener = () => {
      window.addEventListener('scroll', scrollFixed);
    };
    scrollListener();
    return () => {
      window.removeEventListener('scroll', scrollFixed);
    };
  });

  const onClickTab = (index: React.SetStateAction<number>) => setSelectedTab(index);

  return (
    <div className="company-history">
      <div className={`fixed-year-selector ${yearSelectorHide ? 'hidden' : ''}`}>
        <ul>
          <li className={`${yearSelector === 2024 ? 'active' : ''}`}>2024</li>
          <li className={`${yearSelector === 2023 ? 'active' : ''}`}>2023</li>
          <li className={`${yearSelector === 2022 ? 'active' : ''}`}>2022</li>
          <li className={`${yearSelector === 2021 ? 'active' : ''}`}>2021</li>
          <li className={`${yearSelector === 2020 ? 'active' : ''}`}>2020</li>
          <li className={`${yearSelector === 2019 ? 'active' : ''}`}>2019</li>
          <li className={`${yearSelector === 2018 ? 'active' : ''}`}>2018</li>
          <li className={`${yearSelector === 2017 ? 'active' : ''}`}>2017</li>
        </ul>
      </div>
      <div className="section-1">
        <div className="section-1-background">
        </div>
        <div>
          <div className="overlay" />
          <PageTitle title={metaInfo[pathname.slice(1)].subTitle}
                     subTitle="EL Story"
                     description={metaInfo[pathname.slice(1)].description} />
          <WhiteSquare />
          <div className="tab-wrap">
            <Link to="/story" style={{ textDecoration: 'none' }}>
              <div className={`tab ${selectedTab === 1 ? 'selected' : ''}`}
                   onClick={() => {
                     onClickTab(1);
                   }}
              >
                경영철학
              </div>
            </Link>
            <Link to="/history" style={{ textDecoration: 'none' }}>
              <div className={`tab ${selectedTab === 2 ? 'selected' : ''}`}
                   onClick={() => {
                     onClickTab(2);
                   }}
              >
                회사연혁
              </div>
            </Link>
            <Link to="/culture" style={{ textDecoration: 'none' }}>
              <div className={`tab ${selectedTab === 3 ? 'selected' : ''}`}
                   onClick={() => {
                     onClickTab(3);
                   }}
              >
                이엘문화
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="since-block">
          <div className="title">
            <p>since</p>
            <span>2021-2024</span>
          </div>
          <div className="banner">
            <img src={banner1Img} alt="" />
            <p>글로벌 라이프 스타일을 지향하는<br />미디어 커머스 플랫폼 사업 확장</p>
          </div>
          <div className="history-list">
            <div className={`year ${scrollActive ? 'fixed' : ''}`}>
              <p>{sinceBlock1Year}</p>
              <div>
                <img className={`${sinceBlock1Fading ? 'hidden' : ''}`}
                     src={sinceBlock1Img.img}
                     alt={sinceBlock1Img.title} />
              </div>
            </div>
            <div className="list">
              <ul>
                {/* 2024년 */}
                <li>
                  <p className="year">2024</p>
                  <div>
                  <div className="itemGroup">
                      <div>
                        <p>이엘그룹</p>
                        <ul>
                          <li>주식회사 로그앤코 설립</li>
                        </ul>
                      </div>
                    </div>
                    <div className="itemGroup">
                      <Entertainment />
                      <div>
                        <p>Contents</p>
                        <ul>
                          <li className="point">
                            KBS2 &lt;개는 훌륭하다&gt; 제작
                            <i className="imgIcon" />
                            <MobilePointImg param={sinceBlock1List.dogIsGood} />
                          </li>
                          <li>넷플릭스 &lt;짜장면 랩소디&gt;, &lt;치킨 랩소디&gt; 제작</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* 2023년 */}
                <li>
                  <p className="year">2023</p>
                  <div>
                    <div className="itemGroup">
                      <Entertainment />
                      <div>
                        <p>Contents</p>
                        <ul>
                          <li>KBS 50주년 신년기획 &lt;최초의 질문&gt; 제작</li>
                          <li className="point">
                            KBS1 &lt;스카우트4.0 얼리어잡(JOB)터&gt; 제작
                            <i className="imgIcon" />
                            <MobilePointImg param={sinceBlock1List.scoutsEarlyJobter} />
                          </li>
                          <li className="point">
                            채널A &lt;공부를 부탁해, 티처스&gt;, &lt;금쪽같은 내새끼&gt; 제작
                            <i className="imgIcon" />
                            <MobilePointImg param={sinceBlock1List.myChild} />
                          </li>
                          <li>JTBC &lt;안방판사&gt;, &lt;닭 싸움&gt;, &lt;헬프미&gt; 제작</li>
                          <li>tvN &lt;오늘도 주인공&gt;</li>
                          <li className="point">
                            ENA &lt;하하버스&gt;
                            <i className="imgIcon" />
                            <MobilePointImg param={sinceBlock1List.hahaBus} />
                          </li>
                          <li className="effect">
                            <i />이엘 미디어 X ENA 공동제작을 통한 예능 IP 확보
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* 2022년 */}
                <li>
                  <p className="year">2022</p>
                  <div>
                    <div className="itemGroup">
                      <div>
                        <p>이엘그룹</p>
                        <ul>
                          <li>MCN(Sportainer&amp;Influencer)사업부 신설</li>
                        </ul>
                      </div>
                      <div>
                        <p>엘리케이파크</p>
                        <ul>
                          <li>주식회사 엘리케이파크 미국법인 설립</li>
                        </ul>
                      </div>
                    </div>
                    <div className="itemGroup">
                      <Entertainment />
                      <div>
                        <p>Contents</p>
                        <ul>
                          <li>MBC &lt;다큐플렉스 칼과 불&gt; 제작</li>
                          <li className="point">
                            KBS2 &lt;자본주의학교&gt; 제작
                            <i className="imgIcon" />
                            <MobilePointImg param={sinceBlock1List.capitalistSchool} />
                          </li>
                          <li className="effect">
                            <i />이엘미디어, 국내 최초 예능부문 IP 확보
                          </li>
                          <li className="point">
                            MBN &lt;돌싱글즈&gt; 시즌 제작
                            <i className="imgIcon" />
                            <MobilePointImg param={sinceBlock1List.returnSingles2} />
                          </li>
                          <li>JTBC &lt;결혼에 진심&gt;</li>
                          <li>OCN &lt;O씨네&gt;</li>
                          <li>TV조선 &lt;더위대한유산&gt;, &lt;나의 영웅&gt;</li>
                          <li>KBS2 &lt;K-FOOD SHOW : 맛의 나라&gt; 제작</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* 2021년 */}
                <li>
                  <p className="year">2021</p>
                  <div>
                    <div className="itemGroup">
                      <Entertainment />
                      <div>
                        <p>Contents</p>
                        <ul>
                          <li className="point">
                            MBN &lt;돌싱글즈&gt;, &lt;전국방방쿡쿡&gt;
                            <i className="imgIcon" />
                            <MobilePointImg param={sinceBlock1List.returnSingles} />
                          </li>
                          <li className="point">
                            tvN &lt;보쌈:운명을훔치다&gt; 드라마 제작
                            <i className="imgIcon" />
                            <MobilePointImg param={sinceBlock1List.stealFate} />
                          </li>
                          <li>JTBC &lt;바라던바다&gt; &lt;다채로운 아침&gt;</li>
                          <li>OBS &lt;인생다큐&gt;, &lt;마이히어로&gt;</li>
                          <li>KBS2 &lt;백종원클라쓰&gt; 제작</li>
                          <li>넷플릭스 &lt;냉면 랩소디&gt;, &lt;한우 랩소디&gt;</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* 2015-2020년 */}
          <div className="since-block">
            <div className="title">
              <p>since</p>
              <span>2015-2020</span>
            </div>
            <div className="banner">
              <img src={banner2Img} alt="" />
              <p>설립 이후 5년간 기본사업 및 조직기반 구축,<br />종합 엔터테인먼트 미디어 기업으로 도약</p>
            </div>
            <div className="history-list">
              <div className={`year ${scrollActive ? 'fixed' : ''}`}>
                <p>{sinceBlock2Year}</p>
                <div>
                  <img className={`${sinceBlock2Fading ? 'hidden' : ''}`}
                       src={sinceBlock2Img.img}
                       alt={sinceBlock2Img.title} />
                </div>
              </div>
              <div className="list">
                <ul>
                  {/* 2020년 */}
                  <li>
                    <p className="year">2020</p>
                    <div>
                      <div className="itemGroup">
                        <Entertainment />
                        <div>
                          <p>Contents</p>
                          <ul>
                            <li className="point">
                              tvN &lt;악마가 너의 이름을 부를 때&gt; 드라마 제작
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock2List.whenTheDevilCallsYourName} />
                            </li>
                            <li className="point">
                              KBS2 &lt;신상출시 편스토랑&gt;, &lt;슈퍼맨이 돌아왔다&gt;
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock2List.convenienceStoreRestaurant} />
                            </li>
                            <li>KBS Joy &lt;차트를달리는남자&gt;</li>
                            <li>KBS2 &lt;비긴어게인코리아&gt;, &lt;다큐플러스&gt;, &lt;체인지&gt;</li>
                            <li>MBN &lt;지구방위대&gt;</li>
                            <li className="point">
                              OnStyle &lt;겟잇뷰티&gt;
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock2List.getItBeauty} />
                            </li>
                            <li className="point">
                              TV조선 &lt;퍼펙트라이프&gt;
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock2List.perfectLife} />
                            </li>
                            <li>연합뉴스TV &lt;연합뉴스TV스페셜&gt;</li>
                            <li className="point">
                              넷플릭스 &lt;삼겹살랩소디&gt; 시리즈 제작
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock2List.porkBellyRhapsody} />
                            </li>
                          </ul>
                        </div>
                        <div>
                          <p>Music</p>
                          <ul>
                            <li>엘리케이 뮤직웍스 &lt;A letter from Arizona&gt; 발매</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2019년 */}
                  <li>
                    <p className="year">2019</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <p>이엘와이드</p>
                          <ul>
                            <li>주식회사 이엘라이즈 인수</li>
                            <li className="effect">
                              <i />아티스트 매니지먼트 &amp; 커머스 사업부문 확장
                            </li>
                          </ul>
                        </div>
                        <div>
                          <p>이엘미디어</p>
                          <ul>
                            <li>드라마제작사 (주)이엘스토리 흡수합병</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2018년 */}
                  <li>
                    <p className="year">2018</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <p>이엘와이드</p>
                          <ul>
                            <li>주식회사 이엘티비 인수</li>
                            <li>주식회사 이엘미디어컴퍼니 인수</li>
                            <li>주식회사 엘리케이파크 설립</li>
                          </ul>
                        </div>
                      </div>
                      <div className="itemGroup">
                        <Entertainment />
                        <div>
                          <p>Music</p>
                          <ul>
                            <li>영화 &lt;골든슬럼버&gt; OST 제작</li>
                            <li>SBS드라마 &lt;여우각시별&gt; OST 제작</li>
                            <li>KBS드라마 &lt;러블리호러블리&gt; OST 제작</li>
                            <li>안녕 바다 앨범 &lt;701 A-side&gt; | &lt;701 B-side&gt; 발매</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2017년 */}
                  <li>
                    <p className="year">2017</p>
                    <div>
                      <div className="itemGroup">
                        <Entertainment />
                        <div>
                          <p>Music</p>
                          <ul>
                            <li>JTBC드라마 &lt;그냥 사랑하는 사이&gt; OST 제작</li>
                            <li>OCN드라마 &lt;블랙&gt; OST 제작</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyHistory;